import React from "react"
import { library } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { fab } from "@fortawesome/free-brands-svg-icons"
import logo from "../assets/img/logo.png"
import logoFooterMobil from "../assets/img/logo-footer.png"
import dusgen from "../assets/img/dusgen-buton1.png"
import postLogo from "../assets/img/postKreatif-logo.png"
import { useTranslation } from "gatsby-plugin-react-i18next"


library.add(fab)

export default function Footer() {
  const { t } = useTranslation()
  return (
    <footer>
      <div className="footer-container">
        <div className="left">
          <img src={logo} alt="" />
          <div className="copyright">
            Copyright {new Date().getFullYear()}
            <br />
            {t("sakli-haklar")}
          </div>
          <div className="social-media">
            <a href="https://www.facebook.com/bilalakkaya64" target="blank">
              <FontAwesomeIcon icon={["fab", "facebook-square"]} size="2x" />
            </a>
            <a href="https://www.instagram.com/bilalakkayaart" target="blank">
              <FontAwesomeIcon icon={["fab", "instagram-square"]} size="2x" />
            </a>
            <a
              href="https://www.linkedin.com/in/bilal-akkaya-b0909942"
              target="blank"
            >
              <FontAwesomeIcon icon={["fab", "linkedin"]} size="2x" />
            </a>
            <a href="https://twitter.com/bilalakkayaart" target="blank">
              <FontAwesomeIcon icon={["fab", "twitter-square"]} size="2x" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCPPNgHnEixKj0O6FeQPQpZw"
              target="blank"
            >
              <FontAwesomeIcon icon={["fab", "youtube-square"]} size="2x" />
            </a>
            <a
              href="https://dusgen.com"
              target="blank"
              style={{display: "flex", alignItems: "flex-end"}}
            >
              <img src={dusgen} style={{borderRadius: 5, marginBottom: 0}} alt="" />
            </a>
          </div>
        </div>
        <div className="right">
          <a href="https://postkreatif.com" target="_blank" rel="noreferrer">
            <img src={postLogo} alt="" />
          </a>
          <div className="post-copyright">
            {t("post-footer1")}
            <br />
            {t("post-footer2")}
          </div>
        </div>
      </div>
      <div className="footer-container mobil">
        <div className="social-media">
          <a href="https://www.facebook.com/bilalakkaya64" target="blank">
            <FontAwesomeIcon icon={["fab", "facebook-square"]} size="2x" />
          </a>
          <a href="https://www.instagram.com/bilalakkayaart" target="blank">
            <FontAwesomeIcon icon={["fab", "instagram-square"]} size="2x" />
          </a>
          <a
            href="https://www.linkedin.com/in/bilal-akkaya-b0909942"
            target="blank"
          >
            <FontAwesomeIcon icon={["fab", "linkedin"]} size="2x" />
          </a>
          <a href="https://twitter.com/bilalakkayaart" target="blank">
            <FontAwesomeIcon icon={["fab", "twitter-square"]} size="2x" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCPPNgHnEixKj0O6FeQPQpZw"
            target="blank"
          >
            <FontAwesomeIcon icon={["fab", "youtube-square"]} size="2x" />
          </a>
          <a
              href="https://dusgen.com"
              target="blank"
              style={{display: "flex", alignItems: "flex-end"}}
            >
              <img src={dusgen} style={{borderRadius: 5, marginBottom: 0}} alt="" />
            </a>
        </div>
        <div className="logos">
          <img src={logoFooterMobil} alt="" />
          <a href="https://postkreatif.com" target="_blank" rel="noreferrer">
            <img src={postLogo} alt="" />
          </a>
        </div>
        <div className="copyright">
          Copyright {new Date().getFullYear()}.
          {t("sakli-haklar")}
          <br />
          {t("post-mobilfooter")}
        </div>
      </div>
    </footer>
  )
}
