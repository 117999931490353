import { Link, useTranslation } from "gatsby-plugin-react-i18next"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import $ from "jquery"
import Logo from "../assets/img/logo.png"
import { useI18next } from "gatsby-plugin-react-i18next"

const Header = ({ siteTitle }) => {
  const { t } = useTranslation()
  const { changeLanguage, language } = useI18next()
  useEffect(() => {
    $("div.nav").hover(function () {
      $(this).toggleClass("hover")
    })
    $(".nav input").click(function () {
      if ($(this).parent().hasClass("mobilMenuAcik")) {
        $(this).parent().removeClass("mobilMenuAcik")
      } else {
        setTimeout(() => {
          $(this).parent().addClass("mobilMenuAcik")
        }, 600)
      }
      $(this).parent().parent().parent("header").toggleClass("mobilMenuAcik")
    })
    var windowWidth = $(window).width()
    var pathname = location.pathname

    if (windowWidth > 1200) {
      if (pathname !== "/" && pathname !== "/en/" ) {
        $("#navMenu").prop("checked", true)
      }
      if (pathname.indexOf("/en/") === 0) {
        $("#navMenu").addClass("en");
      }
    }
  })

  return (
    <header>
      <div className="header-container">
        <Link to="/" className="logo">
          <img src={Logo} alt="" />
        </Link>
        <div className="nav">
          <input type="checkbox" id="navMenu" />
          <span />
          <span />
          <span />
          <div className="menu">
            <li className="menu-biyografi">
              <Link to="/biyografi/">{t("biyografi").toLocaleUpperCase()}</Link>
            </li>
            <li className="menu-sergiler">
              <Link to="/sergiler/">{t("sergiler").toLocaleUpperCase()}</Link>
            </li>
            <li className="menu-projeler">
              <Link to="/projeler/">{t("projeler").toLocaleUpperCase()}</Link>
            </li>
            <li className="menu-galeri">
              <Link to="/galeri/">{t("galeri").toLocaleUpperCase()}</Link>
            </li>
            <li className="menu-blog">
              <Link to="/blog/">{t("blog").toLocaleUpperCase()}</Link>
            </li>
            <li className="menu-basin">
              <Link to="/basin/">{t("basin").toLocaleUpperCase()}</Link>
            </li>
            <li className="menu-iletisim">
              <Link to="/iletisim/">{t("iletisim").toLocaleUpperCase()}</Link>
            </li>
          </div>
          <div className="mobilMenu">
            <li className="menu-biyografi">
              <Link to="/biyografi/">{t("biyografi").toLocaleUpperCase()}</Link>
            </li>
            <li className="menu-sergiler">
              <Link to="/sergiler/">{t("sergiler").toLocaleUpperCase()}</Link>
            </li>
            <li className="menu-projeler">
              <Link to="/projeler/">{t("projeler").toLocaleUpperCase()}</Link>
            </li>
            <li className="menu-galeri">
              <Link to="/galeri/">{t("galeri").toLocaleUpperCase()}</Link>
            </li>
            <li className="menu-blog">
              <Link to="/blog/">{t("blog").toLocaleUpperCase()}</Link>
            </li>
            <li className="menu-basin">
              <Link to="/basin/">{t("basin").toLocaleUpperCase()}</Link>
            </li>
            <li className="menu-iletisim">
              <Link to="/iletisim/">{t("iletisim").toLocaleUpperCase()}</Link>
            </li>
          </div>
        </div>
        <a
          className="changeLang"
          href="#"
          onClick={() => changeLanguage(language === "tr" ? "en" : "tr")}
        >
          {language === "tr" ? "EN" : "TR"}
        </a>
      </div>
      <div className="mobilFilter"></div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
